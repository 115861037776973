import skillBackgroundImg from '../images/skillBanckgroundImg (1).svg'; 
import Container from './Container';
import reactIcon from '../images/reactIcon.svg'
import nodeIcon from '../images/NodeIcon.svg'
import mongodbIcon from '../images/mongodbIcon.svg'
import figmaIcon from '../images/FigmaIcon.svg'
import gitIcon from '../images/gitIcon.svg'
import typescriptIcon from '../images/typescript.svg'
import pythoIcon from '../images/pythonIcon.svg'
import psIcon from '../images/icons8-photoshop 1.svg'
const skills = [
    { name: 'React.js', img: reactIcon },
    { name: 'Typescript', img: typescriptIcon},
    { name: 'NodeJS', img: nodeIcon },
    { name: 'Photoshop', img:psIcon},
    { name: 'MongoDB', img: mongodbIcon },
    { name: 'Python', img: pythoIcon},
    { name: 'Git', img:gitIcon },
    { name: 'Figma', img: figmaIcon}
];

const SkillsSection = () => {
    return (
        <div 
            id="skills" 
            className="bg-[#3C4E8C] flex flex-col items-center p-8 w-full overflow-hidden "
            // style={{ backgroundImage: `url(${skillBackgroundImg})` }}
        >
            {/* Background Image as a div
            <div 
                className="absolute inset-0 w-full h-full"
            >
                <img 
                    src={skillBackgroundImg} 
                    alt="Skills Background" 
                    className="object-cover w-full h-full object-center"
                />
            </div> */}
            <Container className=''>
                <h2 className=" relative z-10  text-5xl text-center font-bold text-[#F8F9FC] mb-6">Let's Explore My Skills</h2>

                {/* Main div */}
                <div className=" relative z-10  py-8 flex flex-col lg:flex-row justify-between items-center">
                    
                    {/* Text div */}
                    <div className="flex-1 mb-8 md:mb-0 md: xs:text-center lg:text-left ">
                        <p className="text-white text-xl xl:text-2xl 2xl:text-3xl ">
                            Proficiency in Node.js, React, TypeScript, JavaScript, and MongoDB enables developers to build scalable, feature-rich web applications with dynamic user interfaces and efficient backend systems. This stack combines the power of server-side JavaScript, modern UI development, static typing for enhanced productivity, and flexible NoSQL database management.
                        </p>
                        <button className="mt-6 px-8 py-2 bg-white text-[#3C4E8C] text-xl 2xl:texl-4xl rounded-lg hover:bg-blue-700">
                            Learn More
                        </button>
                    </div>

                    {/* Cards div */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-y-5 gap-x-2   p-4 rounded-lg w-full md:w-auto">
                        {skills.map(skill => (
                            <div key={skill.name} className="flex flex-col items-center justify-center xs:h-[150px] md:h-[150px] md:w-[150px] lg:h-[130px] lg:w-[120px] xl:h-[150px] xl:w-[150px] 2xl:h-[170px] 2xl:w-[170px] bg-[#F8F9FC] rounded-lg shadow-md">
                                <img src={skill.img} alt={skill.name} className="h-12 mb-2" />
                                <p className="text-xl font-medium xl:text-2xl 3xl:text-3xl text-gray-800">{skill.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SkillsSection;



























// import { IoIosArrowForward } from "react-icons/io";
// import Container from './Container';
// import Figma_logo from '../images/figma.png'
// import PS from '../images/PS.png'
// import git from '../images/icons8-github-90.png'
// import mongoDB from '../images/icons8-mongodb-96.png'
// import python from '../images/python.png'
// import NodeJs from '../images/node.png'
// import React from '../images/react.png'
// import typeScript from '../images/typescript.svg'
// import skillBackgroundImg from '../images/skillBanckgroundImg (1).svg'

// const Skills = () => {
//     return (
//         <div id="skills" className='w-full flex items-center object-cover' style={{backgroundImage :`${skillBackgroundImg}`}}>
//             <Container>
//                  <div className="">
//                   <h1 className="text-2xl font-bold text-center mb-6"> lets Explore My Skills</h1>
//                  </div>
//                 <div className="flex flex-col lg:flex-row">
//                     {/* text div */}
//                     <div className='leading-7 w-full lg:w-[40%]'>
                       
//                         <p className='pt-4 pb-2 leading-6 font-semibold'>
//                             Proficiency in Node.js, React, TypeScript, JavaScript, and MongoDB enables developers to build scalable, feature-rich web applications with dynamic user interfaces and efficient backend systems. This stack combines the power of server-side JavaScript, modern UI development, static typing for enhanced productivity, and flexible NoSQL database management.
//                         </p>
//                         <div className="flex items-center pt-6">
//                             <button className="group relative px-4 rounded-lg py-2 font-medium text-slate-500 bg-blue-200 transition-colors duration-[400ms] hover:text-indigo-500 flex items-center">Learn More<span className="pt-1 pl-2"><IoIosArrowForward />
//                                 <span className="absolute left-0 top-0 h-[2px] w-0 bg-[#009bdf] transition-all duration-100 group-hover:w-full" />
//                                 <span className="absolute right-0 top-0 h-0 w-[2px] bg-[#009bdf] transition-all delay-100 duration-100 group-hover:h-full" />
//                                 <span className="absolute bottom-0 right-0 h-[2px] w-0 bg-[#009bdf] transition-all delay-200 duration-100 group-hover:w-full" />
//                                 <span className="absolute bottom-0 left-0 h-0 w-[2px] bg-[#009bdf] transition-all delay-300 duration-100 group-hover:h-full" />
//                             </span>
//                             </button>
//                         </div>
//                     </div>

//                     {/* cards main div */}

//                     <div className="lg:w-[60%] w-full py-4 flex flex-wrap sm:gap-4 gap-2 justify-center items-center">
//                         <div className="hover:shadow-none shadow-md shadow-slate-600 hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={React} alt="React" className="w-[30%]" />
//                             <div className="py-5 text-xl font-semibold text-black">React</div>
//                             <div className=" border-blue-200 w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">94%</div>
//                         </div>

//                         <div className=" shadow-md hover:shadow-none shadow-slate-600  hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={typeScript} alt="typeScript" className="w-[30%]" />
//                             <div className="py-5 text-xl font-semibold text-black">TypeScript</div>
//                             <div className=" border-blue-200 w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">83%</div>
//                         </div>
//                         <div className="hover:shadow-none shadow-md shadow-slate-600 hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={NodeJs} alt="NodeJs" className="w-[30%]" />
//                             <div className="py-5 text-xl font-semibold text-black">NodeJS</div>
//                             <div className=" border-blue-200 w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">86%</div>
//                         </div>

//                         <div className="shadow-md hover:shadow-none shadow-slate-600  hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={git} alt="git" className="w-[30%]" />
//                             <div className="py-5 text-xl font-semibold text-black">Git</div>
//                             <div className=" border-blue-200 w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">84%</div>
//                         </div>
//                         <div className="hover:shadow-none shadow-md shadow-slate-600 hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={mongoDB} alt="mongoDB" className="w-[30%]" />
//                             <div className="py-5 text-xl font-semibold text-black">MongoDB</div>
//                             <div className=" border-blue-200 w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">65%</div>
//                         </div>

//                         <div className="shadow-md hover:shadow-none shadow-slate-600 hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={python} alt="python" />
//                             <div className="py-5 text-xl font-semibold text-black">Python</div>
//                             <div className=" border-blue-200 w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">62%</div>
//                         </div>
//                         <div className="shadow-md hover:shadow-none shadow-slate-600  hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={PS} alt="PS" />
//                             <div className="py-5 text-xl font-semibold text-black">PhotoShop</div>
//                             <div className=" w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">93%</div>
//                         </div>
//                         <div className="cursor-pointer hover:shadow-none shadow-md shadow-slate-600  hover:scale-110 duration-500 bg-gray-200 flex flex-col items-center justify-center rounded-xl w-[40%] h-[200px] sm:w-[25%] sm:h-[220px] lg:w-[22%] lg:h-[200px]">
//                             <img src={Figma_logo} alt="figma_logo" />
//                             <div className="py-5 text-xl font-semibold text-black">Figma</div>
//                             <div className=" w-[80%] h-8 flex justify-center items-center bg-blue-200 rounded-xl font-bold">95%</div>
//                         </div>
//                     </div>
//                 </div>

//             </Container>
//         </div>
//     );
// };

// export default Skills;