import Container from "./Container";
import userPic from "../images/hamza manzoor.jpeg";
import topVector from '../images/topvector.svg';
import bottomVector from '../images/bottomvector.svg';
import { ReactComponent as Lightwave } from '../images/light wave.svg';

const Home = () => {
  return (
     <section id="home" className=" bg-[#F8F9FC] min-h-screen flex items-center pb-[100px]">
      <Container className="">
    {/* content div  */}
      <div className="flex flex-col  md:flex-row  justify-between items-center gap-2">
        {/* text-div */}
          <div className="md:w-[60%] md:pt-5 flex flex-col w-full xs:text-center md:text-left">
              <h5 className="flex font-semibold md:mt-6 py-4 text-2xl xs:justify-center md:justify-start md:text-3xl 2xl:text-4xl ">
                Hi , I'm
              </h5>
              <h1 className="text-2xl  font-poppins md:text-4xl lg:text-6xl  md:font-bold lg:font-extrabold 2xl:text-7xl text-primary">
                Hamza Manzoor
              </h1>
             
              <h3 className=" mt-4 font-poppins  md:font-semibold text-2xl md:text-2xl lg:text-4xl lg:font-bold 2xl:text-6xl text-[#7081BB]">
                Full Stack Developer
              </h3>
            
             <div className="py-4">
             <button className=" ">
                <a
                  href="./assets/cv/Hamza Manzoor Full stack.pdf"
                  className=" py-2  px-4 text-xl text-white bg-primary rounded"
                  download="Hamza Manzoor.pdf"
                >
                  Download CV
                </a>
              </button> 
             </div>
         </div>

 {/* {/* image side */}
        <div className="relative md:w-3/5 z-10 h-full w-full flex flex-col justify-center items-center">
         {/* Top Background Vector */}
         <div className="absolute top-0 left-[5%] z-10">
           <img src={topVector} alt="Top Vector" className="h-[70%] w-[70%]" />
         </div>

         {/* Bottom Background Vector */}
         <div className="absolute top-[30%] left-[43%] z-0">
           <img src={bottomVector} alt="Bottom Vector" className="h-[100%] w-[100%]" />
         </div>

         {/* User Image */}
         <div className="relative top-10 left-0 h-[60%]  w-[60%] imgpath z-40">
           <img
             src={userPic}
             alt="User Profile"
             className=" object-cover rounded-full"
           />
         </div>
   </div>     
      
      </div>
      </Container>
      {/* waves */}
      <div className='absolute overflow-hidden  rotate-180 z-20 h-[300px] w-full bottom-0 left-0 pointer-events-none'>
        <Lightwave className='absolute w-[400vw] left-0 top-0 text-[#6074BA] moveLeftWave'/>
        <Lightwave className='absolute w-[400vw] left-0 top-0 text-[#3C4E8C] moveRightWave'/>
       </div>
    </section>  
  );
};
export default Home;    