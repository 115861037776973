import React from 'react';
import Container from './Container';
import mailIcon from '../images/Mailbox.svg'
import callIcon from '../images/call.svg'
import linkedinIcon from '../images/linkedin.svg'
import mapIcon from '../images/map.svg'
import Footer from './Footer';

const Contact = () => {
    return (
        <>
        <div  id="Contact" className='xs:bg-[#617AD2] md:bg-[#F8F9FC]  relative z-0 '>
               {/* right div */}
                <div className='xs:hidden md:block md:w-1/6 absolute -z-[1] bg-[#617AD2] h-full top-0 right-0'>
                </div>

               
            <Container>
                <div className='flex flex-col  md:flex-row  justify-between pt-12'>
                
                <div className=' w-full  md:w-1/2 3xl:w-7/12 '>
                    <h1 className='text-3xl   xs:text-white md:text-[#3C4E8C] font-bold font-poppins xl:text-5xl'>Contact Me</h1>
                    <h2 className='my-2  mb-8 font-light font-poppins xs:text-white md:text-[#3C4E8C] md:mt-4 text-xl sm:text-2xl 2xl:text-4xl text-[rgb(60,78,140)]'>Feel free to contact me  any time. I will get to back to  you as soon as possible!   </h2>
            
            {/* forms div */}
              <div className=" xs:w-full  md:w-11/12 2xl:w-5/6 xs:text-white md:text-[#3C4E8C] text-xl 3xl:text-3xl">
                 {/* name  */}
                 <div className='mt-5'>
                   <h1 className=' ms-2    '>NAME</h1>
                    <input type="text" className='border-b-2 border-gray-400  focus:border-blue-500 w-full  bg-transparent '/>
                 </div>
                 {/*mail*/}
                 <div className='mt-3'>
                   <h1 className=' ms-2  '>Email</h1>
                    <input type="email"  className='border-b-2 border-gray-400  focus:border-blue-500 w-full bg-transparent ' />
                 </div>
                 {/* message */}
                 <div className='mt-3'>
                   <h1 className=' ms-2 '>Message</h1>
                    <input type="text"  className='border-b-2 border-gray-400  focus:border-blue-500 w-full bg-transparent ' />
                 </div>
              </div>
                 {/* button */}
                
                 <button className='xs:bg-white xs:text-[#3C4E8C] md:bg-[#3C4E8C]  md:text-white py-2 mt-16 rounded-lg w-1/2  text-xl 3xl:text-3xl'>Submit</button>
               

                </div>


                {/* info div */}
                <div className='bg-[#3C4E8C] px-2 py-6 rounded-lg relative top-4 w-full mt-3  md:w-1/2 xl:w-2/5  md:px-5'>
                {/* small div  */}
                <div className="hidden md:block w-10 h-10 bg-[#617AD2] rounded-md absolute -top-4 -left-4"></div>

                <h1 className='text-white mt-4 ms-5 font-poppins text-3xl md:text-2xl 3xl:text-4xl font-bold'>Info</h1>
                 {/* Icons and links */}
                <div className="mt-3 ">
                    {/* Phone Link */}
                       <div className='flex flex-row gap-3 my-2'>
                            <img src={callIcon} className=' my-1 h-6 w-6' alt="Call Icon" />
                            <a href='tel:+923037324393' className='text-white font-poppins text-2xl md:text-lg lg:text-2xl 3xl:text-3xl'>+923037324393</a>
                        </div>

                        {/* Mail Link */}
                        <div className='flex  flex-row gap-3 my-2'>
                            <img src={mailIcon} className='h-6 w-6 my-1' alt="Mail Icon" />
                            <a href='mailto:hamzamanzoor8234@gmail.com' className=' text-white  font-poppins text-2xl md:text-lg lg:text-2xl 3xl:text-3xl'>hamzamanzoor8234@gmail.com</a>
                        </div>

                        {/* LinkedIn Link */}
                        <div className='flex flex-row gap-3 my-2'>
                            <img src={linkedinIcon} className=' h-6 w-6' alt="LinkedIn Icon" />
                            <a href='https://www.linkedin.com/in/hamzamanzooruiux/'  target='_blank'  rel='noopener noreferrer'  className='text-white font-poppins text-2xl md:text-lg lg:text-2xl 3xl:text-3xl'>
                            hamzamanzooruiux
                            </a>
                        </div>

                    {/* Address */}
                    <div className='flex flex-row gap-3 my-2'>
                    {/* Add your address content here */}
                    </div>

                    {/* map */}
                    <div className="mt-2 pt-2">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d217898.05622262493!2d73.08930095!3d31.423571499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392242a895a55ca9%3A0xdec58f88932671c6!2sFaisalabad%2C%20Punjab!5e0!3m2!1sen!2s!4v1713428652204!5m2!1sen!2s" className='w-full h-52 rounded-xl'  style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                     {/* icons and  text div end */}
                </div>
                   {/* info div end */}
                </div>
             
             </div>

            </Container>
            <Footer/>
        </div>
     
        </>

    );
};

export default Contact;
