import Container from './Container';
import aboutUserImg from '../images/aboutUserImg.svg'
import { ReactComponent as Lightwave } from '../images/light wave.svg';
import lowerImg from '../images/Group 16.svg'
const About = () => {
    return (
        <div className="bg-[#F8F9FC] -mt-1">
       
        <div  id="about" className="  bg-[#3C4E8C] py-3">
         <Container>
            {/* main div */}
           <div className="flex flex-col lg:flex-row justify-between items-center">
                {/* user div */}
                <div className="">
                    <img src={aboutUserImg} className=' rounded-xl sm:max-w-screen-[400px] lg:max-h-[516px] lg:max-w-[527px]' alt="" />
                </div>
                {/* text div */}
                <div className=" xs:px-1  xs:text-center md:text-left xl:ms-28">
                    <h1 className='font-bold text-[#F8F9FC] text-5xl '>
                        About Me
                    </h1>
                    <h1 className='font-semibold my-3 text-[#F8F9FC] text-3xl 2xl:text-4xl'>
                        Here are some words about me 
                    </h1>

                    <h2 className='my-2 py-1 text-[#F8F9FC] text-lg sm:text-center lg:text-left xl:text-2xl 2xl:text-3xl'>
                    At Xeurix, our team's commitment to excellence in web application development is unwavering. With a strong foundation in Computer Software Engineering from the University of Sargodha, our focus is on creating high-impact React.js and Node.js solutions. We prioritize seamless collaboration with cross-functional teams to meet project requirements and deliver on time.
                    </h2>

                    <h2 className='my-2 text-[#F8F9FC] text-lg  sm:text-center lg:text-left xl:text-2xl  2xl:text-3xl '>
                    As a mentor and visiting lecturer at Beaconhouse International College, I blend academic perspectives with real-world development practices, fostering a robust learning environment. This dual role enhances my ability to provide constructive feedback, resolve complex technical challenges, and ensure our web solutions set industry standards for innovation and user experience.
                    </h2>
                </div>
           </div>
         </Container>   
        </div>
        <div className='relative overflow-hidden z-20 h-[300px]'>
        <Lightwave className='absolute w-[400vw] left-0 top-0 text-[#6074BA] moveLeftWave'/>
        <Lightwave className='absolute w-[400vw] left-0 top-0 text-[#3C4E8C] moveRightWave'/>

        </div>
         {/* <img src={lowerImg} className='-mt-1'/> */}
         </div>
    );
};

export default About;
