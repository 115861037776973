import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Navbar from "../src/Components/Navbar";
import Home from "./Components/Home";
import About from "./Components/About";
import WhatIDo from "./Components/WhatIDo";
import Skills from "./Components/Skills";
import Exprience from "./Components/Experience";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import BackgroundAnimation from "./Components/BackgroundAnimation";
// import Footer from "./Components/Footer";

const App = () => {
  // const routers = createBrowserRouter([{
  //   path: '/',
  //   element: <Home />,

  // },
  // {
  //   path: '/About',
  //   element: <About />,
  // }
  // ])
  return (
    <div>
      <BackgroundAnimation />
      <Navbar />
      <Home />
      {/* <RouterProvider router={routers} /> */}
      <About />
      <WhatIDo />
      {/* <div className='md:flex md:flex-row  w-[100%] h-100vh'> */}
      <Skills />
      <Exprience />
      {/* </div> */}
      <Projects />
      <Contact />
      
    
    </div>
  );
};

export default App;
