import Container from './Container';
import{ ReactComponent as MobileAppIcon} from '../images/Mobileapp.svg';
import {ReactComponent as WebsiteIcon} from '../images/Website.svg';
import {ReactComponent as DesigningIcon} from '../images/Designing.svg';
import {ReactComponent as AiIcon} from '../images/Ai development.svg';

import {ReactComponent as CloudIcon} from '../images/cloud.svg';
import {ReactComponent as MachineIcon} from '../images/machineIcon.svg';

const services = [
  { 
    title: "Mobile App Development",
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: <MobileAppIcon/>
  },
  { 
    title: "Websites Development", 
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: <WebsiteIcon/>
     },
  { 
    title: "Website Designing",
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
      icon: <DesigningIcon/>
    },
  {
     title: "AI Development", 
     additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: <AiIcon/>
     },
  {
     title: "Machine Learning",
     additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
       icon: <MachineIcon/>
      },
  { 
    title: "Cloud Services", 
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon:<CloudIcon/>
     }
];


const WhatIDo = () => {
  return (
    <div id="WhatIDo" className="bg-[#F8F9FC] pb-7">
    <Container>
      <div>
        <h1 className="text-center text-5xl font-bold text-[#3C4E8C] mb-8">WHAT I DO</h1>
  
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mx-auto">
          {services.map((service, index) => (
            <div key={index} className="relative w-full h-[400px] group">
              {/* Card */}
              <div className="relative bg-[#F8F9FC] p-6 w-full h-full shadow-lg rounded-lg transition-transform duration-300 ease-in-out group-hover:shadow-lg overflow-hidden">
                
                {/* Icon + Title positioned at the bottom initially */}
                <div
                  className="absolute w-4/5 bottom-20 left-1/2 transform -translate-x-1/2 flex flex-col items-center
                   transition-transform duration-300 ease-in-out xs:group-hover:-translate-y-[90px]
                   sm:group-hover:-translate-y-[130px] md:group-hover:-translate-y-[120px] lg:group-hover:-translate-y-[130px]
                   xl:group-hover:-translate-y-[100px] 2xl:group-hover:-translate-y-[200px] 3xl:group-hover:-translate-y-[160px]">
                  
                  {service.icon && ( service.icon  )}
                  
                  <h2 className="text-xl text-center xl:text-2xl font-bold text-blue-800">
                    {service.title}
                  </h2>
                </div>
  
                {/* Optional: Additional content that shows on hover */}
                <div className="absolute text-center inset-x-0 bottom-2 mx-auto w-11/12 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out opacity-0 group-hover:opacity-100 p-4 text-gray-600">
                  <p className="2xl:text-2xl">{service.additionalContent}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  </div>
  
  );
};

export default WhatIDo;
