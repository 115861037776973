import React from 'react';
import { useEffect,useState } from 'react';
import footerBackgrounImg from '../images/footerWaves.svg'
import { ReactComponent as Lightwave } from '../images/light wave.svg';
import Container from './Container';
import Logo from '../images/LogoWhite.svg'
import vectorLogo from '../images/Vector.svg'
import intsa from '../images/instagram-icon-1.svg'
import facebook from '../images/facebook-f-brands-solid (1).svg'
import X from '../images/x-2.svg'
const Footer = () => {
  const [isMdOrLarger, setIsMdOrLarger] = useState(false);

  // Function to check screen size
  const handleResize = () => {
    setIsMdOrLarger(window.matchMedia('(min-width: 768px)').matches);
  };

  useEffect(() => {
    // Check screen size on initial render
    handleResize();

    // Add event listener to check screen size on window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="">
  {/* //   <div 
  //   id="Footer" 
  //   className={`w-full mt-5 bg-cover bg-center pb-2 ${!isMdOrLarger ? 'bg-none' : ''}`} 
  //   style={isMdOrLarger ? { backgroundImage: `url(${Lightwave})` } : {}}
  // > 
  // className={`relative overflow-hidden  rotate-180 z-20 h-[300px] ${!isMdOrLarger ? 'bg-none' : ''}`*/}
      <div className='relative overflow-hidden  rotate-180 z-20 xs:h-[400px] '>
        <Lightwave className='absolute w-[400vw] left-0 top-0 text-[#6074BA] moveLeftWave'/>
        <Lightwave className='absolute w-[400vw] left-0 top-0 text-[#3C4E8C] moveRightWave'/>
       </div>
          <Container className='absolute z-20 bottom-0 left-[5%]  '>
           
{/* content dive */}
            <div className="  flex flex-col justify-between items-center md:flex-row ">
                {/* logo div */}
              <div className=' flex flex-row '>
                    <div className=' mt-2 ps-2'>
                    <img src={vectorLogo} className="h-11 w-32  "/>
                    </div>
                      <section className="animation -ms-8 w-full">
                      <div className="first  "><p className='text-white text-2xl lg:text-2xl xs:font-bold'>Hamza Manzoor</p></div>
                      <div className="second "><p className='text-white text-2xl  md:text-base lg:text-2xl'>Ui/Ux Designer</p></div>
                      <div className="third " ><p className='text-white text-2xl md:text-base lg:text-2xl'>Fullstack Developer</p></div>
                    </section>
               </div>
               <div className="xs:hidden  md:w-[2px] h-[80px] bg-slate-100"></div>
{/* Follow me div  main */}
            <div className="xs:mt-6 ">
              <h1 className="text-white text-xl">Follow me </h1>
              {/* Icons div */}
            <div className="flex mt-1 mb-2 space-x-4">
{/* ints div */}
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-slate-300">
                  <a href="#" aria-label="Instagram">
                    <img src={intsa} className="h-5 w-5 hover:opacity-75" alt="Instagram" />
                  </a>
                </div>
                {/* facebook div */}
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-slate-300">
                  <a href="#" aria-label="Instagram">
                    <img src={facebook} className="h-5 w-5 hover:opacity-75" alt="Instagram" />
                  </a>
                </div> 
                {/* X div */}
                <div className="h-10 w-10 rounded-full flex items-center justify-center bg-slate-300">
                  <a href="#" aria-label="Instagram">
                    <img src={X} className="h-5 w-5 hover:opacity-75" alt="Instagram" />
                  </a>
                </div> 
              </div>

            </div>
               

                {/* Rights Section copyright */}
                <div className="xs:mt-3   text-center md:w-1/4 ">
                    <span className="block text-white">2024.</span>
                    <span className='text-white'>All rights reserved by Hamza Manzoor</span>
                </div>
                 
              </div>
            

          </Container>
      </div>
  );
};

export default Footer;
