
import { ReactComponent as Logo } from "../images/main_logo.svg";
import { FC, useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import Container from "./Container";

const NavLink: FC<{ title: string; link: string }> = ({ title, link }) => {
  return (
    <li className="cursor-pointer">
      <a
        className="p-6 text-primary text-xl block font-semibold hover:bg-primary hover:text-white transition"
        href={link}
      >
        {title}
      </a>
    </li>
  );
};

const Navbar: FC = () => {
  const [nav, setNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClick = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`w-full fixed top-0 left-0 z-50 transition-colors duration-300 ${scrolled ? 'bg-[#f6f8fa]' : 'bg-transparent'}`}>
      <Container>
        <div className="flex justify-between items-center">
          <div className="flex cursor-pointer  text-primary items-center py-4 w-[250px] ">
            <Logo />
          </div>

          <div className="md:px-4">
            <ul className="hidden md:flex ">
              <NavLink title="Home" link="#home"/>
              <NavLink title="About" link="#about" />
              <NavLink title="Experience" link="#experience" />
              <NavLink title="Project" link="#projects" />
              <NavLink title="Contact" link="#contact" />
            </ul>
          </div>

          {/* Hamburger Icon */}
          <div
            onClick={handleClick}
            className="z-30 flex items-center text-3xl md:text-2xl py-4 md:hidden cursor-pointer"
          >
            {!nav ? <GiHamburgerMenu /> : <FaTimes />}
          </div>

          {/* Mobile Menu */}
          <ul
            className={
              !nav
                ? "hidden"
                : "absolute z-20 min-h-screen top-0 left-0 w-full flex flex-col items-center justify-center bg-[#f6f8fa] md:hidden"
            }
          >
            <li className="py-4 text-[#009bdf] font-semibold text-4xl">
              <a href="#home" onClick={handleClick}>
                Home
              </a>
            </li>
            <li className="py-4 text-[#009bdf] font-semibold text-4xl">
              <a href="#about" onClick={handleClick}>
                About
              </a>
            </li>
            <li className="py-4 text-[#009bdf] font-semibold text-4xl">
              <a href="#projects" onClick={handleClick}>
                Project
              </a>
            </li>
            <li className="py-4 text-[#009bdf] font-semibold text-4xl">
              <a href="#experience" onClick={handleClick}>
                Experience
              </a>
            </li>
            <li className="py-4 text-[#009bdf] font-semibold text-4xl">
              <a href="#contact" onClick={handleClick}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;















