import pic1 from '../images/pic1.webp'
import pic2 from '../images/pic2.webp'
import pic3 from '../images/pic3.webp'
import pic4 from '../images/pic4.webp'
import pic6 from '../images/pic6.webp'
import Container from './Container';

const Projects = () => {
    return (
        <div id='projects' className='flex items-center  bg-[#f6f8fa]'>
            <div className=' w-full '>
                <Container>
                    <div className='py-12 md:pt-12  md:pb-24'>
                        <h1 className='text-[#3C4E8C] md:text-5xl text-2xl font-bold'>Projects</h1>
                    </div>
                </Container>
                <div className='w-full gap-4 flex overflow-hidden '>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic1} alt="pic1" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic2} alt="pic2" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic3} alt="pic3" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic6} alt="pic6" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                </div>
                <div className='w-full cursor-pointer gap-4 pt-8 flex overflow-hidden '>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic4} alt="pic4" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic2} alt="pic2" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic6} alt="pic6" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic1} alt="pic1" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                </div>
                <div className='w-full  cursor-pointer gap-4 pt-8 flex overflow-hidden '>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic1} alt="pic1" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic3} alt="pic3" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic4} alt="pic4" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                    <div className='md:w-[27%] cursor-pointer hover:scale-110 duration-150 '>
                        <img src={pic3} alt="pic3" className='w-[-100px] hover:bg-black hover:opacity-95' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;